
export const ROLES ={
    CARTEAV_ADMINISTRATOR:'CarteavAdministrator',
    ADMIN:'Admin',
    MANAGER:'Manager',
    OPERATOR:'Operator',
    SITE_MANAGER:'SiteManager',
}

export const SCOPES = {
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view", 
    canCreate: "can-create"
};

export const PERMISSIONS = {
    [ROLES.SITE_MANAGER]: [SCOPES.canView],
    [ROLES.OPERATOR]: [SCOPES.canView],
    [ROLES.MANAGER]: [SCOPES.canView, SCOPES.canEdit],
    [ROLES.ADMIN]: [
      SCOPES.canView,
      SCOPES.canEdit,
      SCOPES.canDelete
    ],
    [ROLES.CARTEAV_ADMINISTRATOR]:[
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ]
};
export const ALLOWED_COMPONENTS = {// specific components that are allowed for specific roles
//   'Site params': ,
//   'App params': 
//   'Admin params': [ROLES.ADMIN]
}

export const ROLES_ARRAY = Object.values(ROLES);

//List of allowed users roles for each protected page 
export const ROLES_PAGES ={
    permissions :[ROLES.ADMIN,ROLES.OPERATOR,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard :[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.SITE_MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    cart_info:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_siteLogs:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_events:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_siteManagement:[ROLES.ADMIN,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_cartConfiguration:[ROLES.ADMIN,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_customers:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_reservations:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_mailList:[ROLES.ADMIN,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_site:[ROLES.SITE_MANAGER,ROLES.ADMIN,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_statistics:[ROLES.SITE_MANAGER,ROLES.ADMIN,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
    dashboard_missions: [ROLES.SITE_MANAGER,ROLES.ADMIN,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR], // Add roles that are allowed to view the Missions page

    not_allowed:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.CARTEAV_ADMINISTRATOR],
}

export const SITES_LIST=[
    'develop',
    'company',
    'pilot',
    'demo'
]
