import { createContext, useEffect , useState } from "react";
import RefreshToken from "../../hooks/Auth/refreshToken";
import axios from "axios";
import { getTimeZoneFromAllSites } from "../../services/getTimeZoneFromAllsItes";
import { getMapDataObjectFromAllSites } from "../../services/getMapDataObjectFromAllSites";
const AuthContext = createContext({});

export const AuthProvider = ({ children  }) => {
      
    const [auth, setAuth] = useState({});
    const [role,setRole] = useState(null);
    const [loading,setLoading] = useState(true);
    const [relevantUrl,setRelevantUrl] = useState(null);
    const [sitesMapsDataObject,setSitesMapsDataObject]=useState(null);
    const [relevantSite,setRelevantSite] = useState(null);
    const [relevantMapData , setRelevantMapData] = useState(null);
    const [sitesList,setSiteList] =useState(null);
    const [sitesSockectsConnection ,setSitesSockectsConnection]=useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [configData, setConfigData] = useState({}); 



    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    //eventObject = {
    //     "develop":[eventsList],
    //     "company":[eventsList]
    // }

    //mapDataObject = {
    //     "develop":[mapDataObject],
    //     "company":[mapDataObject]
    // }

    useEffect( ()=>{
        const getToken = async ()=>{
            try {
                const response = await RefreshToken();
                if(response){
                     setAuth(response);
                }
                 setLoading(false);
                 console.log("finish refresh token")
                } catch (error) {}
        }
        getToken();
    },[])

    useEffect(()=>{
        let url;
        

        // when user change site , update the relvant states 
        if(sitesMapsDataObject && relevantSite && Object.prototype.hasOwnProperty.call(sitesMapsDataObject, relevantSite)){
            setRelevantMapData(sitesMapsDataObject[relevantSite]);
        }

        if(relevantSite !== 'localhost') {
            url = "https://" + relevantSite+'.carteav.com';
        }else{
            url = "http://localhost:8080";
        }
        setRelevantUrl(url);

    },[relevantSite,sitesMapsDataObject]);

    useEffect(()=>{
        let intervalId ;
        
        if(auth && Object.keys(auth).length>0){
            let developmentAuth = auth;

            // only for development
            if(window.location.href.includes('localhost')){
                developmentAuth.relevant_site= 'localhost'
                // ,//'company'
                    developmentAuth.sites_list = ['localhost','company','gandev','develop','barilan',"disney","epperson","check"];
            }
            
            let relevantUrlDevelopment="";
            if(developmentAuth.relevant_site !== 'localhost'){
                // production
                relevantUrlDevelopment = `https://${developmentAuth.relevant_site}.carteav.com`;
            }else{
                // only for development
                relevantUrlDevelopment = 'http://localhost:8080';

            }

            setSiteList(developmentAuth.sites_list);
            let currentRelevantSite = relevantSite;

            if(relevantSite == null ){
                currentRelevantSite =developmentAuth.relevant_site; 
                setRelevantSite(developmentAuth.relevant_site);
            }
            setRelevantUrl(relevantUrlDevelopment);
            setAuth(developmentAuth);
            setRole(auth.roles);


            setMapDataFromAllSitesFunction(developmentAuth.sites_list , currentRelevantSite);
        }

        return () => clearInterval(intervalId); 

    },[auth])


    const setMapDataFromAllSitesFunction = async (site_list) => {
        getMapDataObjectFromAllSites(site_list,setSitesMapsDataObject);
    };


  const fetchConfigData = async () => {
    try {
      const response = await axios.post(relevantUrl + '/admin/get_config_json');
      setConfigData(response.data);
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };


  const getConfigValue = (key) => {
    for (const section in configData) {
      const paramsArray = configData[section];
      
      if (Array.isArray(paramsArray)) {
        for (const param of paramsArray) {
          if (param.name === key) {
            return param.value;  
          }
        }
      }
    }
    return null;
  };
  

  useEffect(() => {
    if (relevantUrl) {
      fetchConfigData();
    }
  }, [relevantUrl]);

    if(!loading){
    return (
        <AuthContext.Provider 
            value={{
                relevantMapData, 
                setRelevantMapData, 
                setSitesMapsDataObject, 
                role,
                auth, 
                setAuth,
                relevantUrl,
                setRelevantUrl,
                sitesList,
                sitesSockectsConnection,
                setSitesSockectsConnection,
                relevantSite,
                setRelevantSite,
                sitesMapsDataObject,
                windowWidth,
                getConfigValue
            }}
        >
            {children}
        </AuthContext.Provider>
    )
    }else{
        return null;
    }
}

export default AuthContext;