import React,{useRef,useEffect,useState} from 'react';
import { useHistory,useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SiteOverviewIcon } from "../../../assets/icons/general/statistics.svg"
import { ReactComponent as SiteIcon } from "../../../assets/icons/general/siteIcon.svg"
import { ReactComponent as WhiteLogo } from "../../../assets/icons/carteavLogo/carteav_whiteIcon.svg"
import { ReactComponent as CarteavLogo } from "../../../assets/icons/carteavLogo/carteavicon.svg"

import { ReactComponent as LogoutUserIcon } from "../../../assets/icons/general/logoutUserIcon.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/icons/general/notificationIcon.svg"
import packageJson from '../../../../package.json';
import { ReactComponent as CloseSideBarIcon } from "../../../assets/icons/sideBar/arrowBack.svg"
import useAuth from '../../../hooks/Auth/useAuth';
import Fab from '@mui/material/Fab';
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux'
import {ROLES_PAGES,ROLES} from '../../../redux/context/allowedTypes';
import styles from './appBar.module.css';
import { ReactComponent as Cart_management } from "../../../assets/icons/sideBar/Cart_management.svg" 
import { ReactComponent as Permissions } from "../../../assets/icons/sideBar/Permissions.svg" 
import { ReactComponent as User } from "../../../assets/icons/sideBar/User.svg" 
import { ReactComponent as Settings } from "../../../assets/icons/sideBar/Settings.svg" 
import { ReactComponent as Site_events } from "../../../assets/icons/sideBar/Site_events.svg" 
import { ReactComponent as Site_overview } from "../../../assets/icons/sideBar/Site_overview.svg" 
import { ReactComponent as Stats } from "../../../assets/icons/sideBar/Stats.svg" 
import { ReactComponent as Site_management } from "../../../assets/icons/sideBar/Site_management.svg" 
import { ReactComponent as Rateride } from "../../../assets/icons/sideBar/Rateride.svg";
import { ReactComponent as Missions } from "../../../assets/icons/sideBar/missions.svg";

import Event from '../events/Event';

const settings = [ 'Logout'];
const SITE_MANAGER_PAGES =[
  {
    display: 'Site overview',
    icon: <SvgIcon > <SiteOverviewIcon /> </SvgIcon>,
    to: '/dashboard_site',
    section: 'dashboard',
    allowed:ROLES_PAGES.dashboard_site
  },
  {
    display: 'Statistics',
    icon: <SignalCellularAltIcon/> ,
    to: '/dashboard_statistics',
    section: 'dashboard_statistics',
    allowed:ROLES_PAGES.dashboard_statistics
  },
];

const ADMIN_PAGES = [
  {
    display: 'Site overview',
    icon:  <Site_overview />,
    to: '/dashboard_site',
    section: 'dashboard',
    allowed:ROLES_PAGES.dashboard_site
  },
  {
      display: 'Event history',
      icon: <Site_events /> ,
      to: '/dashboard_events',
      section: 'dashboard_events',
      allowed:ROLES_PAGES.dashboard_reservations
  },
  {
      display: 'Cart management',
      icon:   <Cart_management />,
      to: '/dashboard_cartConfiguration',
      section: 'dashboard_cartConfiguration',
      allowed:ROLES_PAGES.dashboard_cartConfiguration
  },
  {
      display: 'Site Management',
      icon:   <Site_management /> ,
      to: '/dashboard_siteManagement',
      section: 'dashboard_siteManagement',
      allowed:ROLES_PAGES.dashboard_siteManagement
  },
  {
    display: 'Statistics',
    icon:   <Stats />,
    to: '/dashboard_statistics',
    section: 'dashboard_statistics',
    allowed:ROLES_PAGES.dashboard_statistics
  },
  
  {
    display: 'Permissions',
    icon:  <Permissions />,
    to: '/permissions',
    section: 'permissions',
    allowed:ROLES_PAGES.permissions
  },
  {
    display: 'Missions', // Adding the Missions page
    icon: <Missions />, // Use the appropriate icon
    to: '/dashboard_missions', // Specify the correct path for the missions page
    section: 'dashboard_missions',
    allowed: ROLES_PAGES.dashboard_missions // Ensure this permission is defined
  },
  {
    display: 'Feedbacks', // New page entry
    icon: <Rateride />, 
    to: '/dashboard_feedbacks',
    section: 'dashboard_feedbacks',
    allowed: ROLES_PAGES.dashboard_cartConfiguration // Adjust permissions as needed
  },
  
]

const ADMIN_BOTTOM_SETTINGS=[
  {
    display: 'Settings',
    icon:   <Settings />,
    to: '/user_settings',
    section: 'user_settings',
    allowed:ROLES_PAGES.dashboard_statistics
  },
  
  {
    display: 'User',
    icon:  <User />,
    to: '/user_profile',
    section: 'user_profile',
    allowed:ROLES_PAGES.permissions
  },
]


const ResponsiveAppBar = () => {


  let {
      role,
      setAuth, 
      auth,
      setRelevantSite,
      relevantSite,
    } = useAuth();
  const serverHost = useSelector((state) => state.serverHost);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();
  const focusPageRef = React.useRef(null);
  const location = useLocation();
  const [sideBarIsopen,setSideBarIsOpen] = React.useState(false);
  const refAdminBar = React.useRef(null);
  const refAdminRightSettingsBar = React.useRef(null);
  const [sitesEventObject,setSitesEventObject] = React.useState({});
  const { version } = packageJson;

     
  let errorLogged = {};
  const getEventsFromAllSites =async (site_list)=>{
     
  let object ={};
  for (let site of site_list) {
      let url;
      if(site !== 'localhost') {
          url = "https://" + site+'.carteav.com';
      }else{
          url = "http://localhost:8080";
      }
      try {
        const resonse = await  axios.get(url + `/admin/getAllEvents`);
        if(resonse.data !== 'err'){
          object[site] = resonse.data;
        }

         // Clear error flag for the current site if successful
          delete errorLogged[site];

      } catch (error) {
        if (!errorLogged[site]) {
          console.log("Error occurred while fetching events for site " + site + ": " + error);
          errorLogged[site] = true;
        }
      }
  }
  setSitesEventObject(object);
}



const scrollLeftAdminSitesList =(p_scrollTo)=>{
  if(refAdminBar && refAdminBar.current){
    const adminTopcontainer = refAdminBar.current;
    if(adminTopcontainer){
      if(p_scrollTo === 'left'){
        adminTopcontainer.scrollLeft -= 80;
      }else if (p_scrollTo === 'right'){
        adminTopcontainer.scrollLeft += 80;
      }
    }
  }
}


  useEffect(()=>{
    let intervalId ;
    if(role != null && role !== ROLES.SITE_MANAGER && auth.sites_list && intervalId == null ){
      intervalId = setInterval(()=>{
          getEventsFromAllSites(auth.sites_list);
        },2000);
    }

    return ()=> clearInterval(intervalId);
  },[role,auth])


  const currentPath = location.pathname;
  React.useEffect(()=>{
      
      if(auth?.accessToken && auth?.userName){
        sessionStorage.setItem("currentPath",currentPath);
    
      }
      if(focusPageRef.current != null){
          focusPageRef.current.page = currentPath;
      }
    }, [location]);
  

  const handleOpenUserMenu = (event) => {
    console.log(event.currentTarget);
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseNavMenu = (to) => {
    history.push(to);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  
  };

  const handleNavToPage = (to)=>{
    history.push(to);
  
  }

  const handleLogout=  async (event) => {
  
    event.preventDefault();
    try {
      const response = await axios.post(`${serverHost}/user/logout` ,{ withCredentials: true })
      console.log(response);
      setAuth({})
      setAnchorElUser(null)
      handleCloseNavMenu('/login');
    } catch (error) {
      console.log(error);
    }
  }
  

  const handleChangeSite=(p_site_name)=>{
    
    setRelevantSite(p_site_name);
    localStorage.setItem('relevantSite', p_site_name);
  }


  const settingsContainerRef = useRef(null);
  const eventContainerRef = useRef(null);
  // State variables for opening and closing the events container
  const [eventsContainerOpen, setEventsContainerOpen] = useState(true);
  
  const toggleEventsContainer = () => {
    setEventsContainerOpen(prevState => !prevState);
};




  const isAdmin = auth && auth.userName && role !== null && !currentPath.includes("/login") &&  role !== ROLES.SITE_MANAGER ? true : false;  

  const adminTopBArOverfllowingControlButtonsContainerWidth = 110;
  const isAdminTopSitesListOverFllowing =refAdminBar && refAdminBar.current &&  refAdminBar.current.scrollWidth > refAdminBar.current.offsetWidth;
  const isTabletorMobileMaxWidth = 1224;
  // const adminTopBarRighSettingsWidth =  isTabletorMobile && isAdmin  ? 120: 60; 
  const adminTopBarRighSettingsWidth =  isAdmin  ? 120: 60; 
  const adminSitesListMaxWidth =  isAdminTopSitesListOverFllowing ? adminTopBarRighSettingsWidth + 60 + adminTopBArOverfllowingControlButtonsContainerWidth :  adminTopBarRighSettingsWidth + 60;  

  
  return (
    <>   
    <AppBar 
      position='sticky' 
      elevation={0} 
      className={isAdmin ? styles.admin_appBar: styles.regular_appBar } // Apply CSS module class
    >
        {/* LOGO ICON - left side  */}
        <Toolbar disableGutters={true}>
        {
            !auth.userName ||  currentPath.includes("/login")   
          ? 
                <>
                  <div className={styles.logoContainer}>
                    <SvgIcon className={styles.logoIcon} > 
                      <CarteavLogo /> 
                    </SvgIcon>
                  </div>
                  <div className={styles.side_bar_top_logo_text} style={{color:'var(--black)',fontSize:'1.2rem',lineHeight:'40px'}}>
                    CARTEAV
                  </div>
               </>
          : 
            null 
        }

          {/* SITES LISTS - site manager (only one) ,admin (according to sites list) */}
          {
              auth.userName && !currentPath.includes("/login") 
            ? 
                !isAdmin
                  ? 
                  <>
                  <div className={styles.logoContainer} style={{marginRight:'5rem'}}>
                    <SvgIcon className={styles.logoIcon}> 
                      <CarteavLogo />  
                    </SvgIcon>
                  </div>
                  <div className={styles.side_bar_top_logo_text} style={{color:'var(--black)',left:'55px',fontSize:'1.2rem',lineHeight:'40px'}}>
                  { auth.relevant_site ? auth.relevant_site : null}     
                  </div>
                  <div className={styles.horizontal_container}/>

               </>
                 
                  :
                    <div ref={refAdminBar}  className={styles.admin_top_bar}>
                      {isAdminTopSitesListOverFllowing && 
                        <div style={{
                          width:`${adminTopBArOverfllowingControlButtonsContainerWidth}px`,
                          display:'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          position:'absolute',
                          top: '0',
                          height:'100%',
                          margin:0,
                          padding:0,
                          right:`${adminTopBarRighSettingsWidth}px`,
                        }}>
                          <div id="admin_top_bar_button_divider_left" className={styles.admin_top_bar_button_divider}/>
                          <div id='admin_top_bar_button_left' className={styles.admin_top_bar_button_left} onClick={()=>scrollLeftAdminSitesList('left')} />
                          <div id='admin_top_bar_button_right' className={styles.admin_top_bar_button_right} onClick={()=>scrollLeftAdminSitesList('right')} />
                          <div id="admin_top_bar_button_divider_right" className={styles.admin_top_bar_button_divider} />
                        </div>
                      }
                      <div id="adminTopcontainerSitesList" style={{maxWidth: `calc(100vw - ${adminSitesListMaxWidth}px )` }} className={styles.topBar_left_sites}>
                        { 
                          auth.sites_list.length >= 1 
                            ? 
                                (auth.sites_list.map((site)=> (
                                      
                                  <React.Fragment key={site}>
                                    {/* admin top bar site name container  */}
                                    <div style={{
                                          display:'inline-block',
                                          backgroundColor:relevantSite === site ? '#E4E6EB' : null,
                                          borderRadius:'8px 8px 0 0' ,
                                          borderBottom:relevantSite === site ? '3px solid #212028' : '3px solid white',
                                          position:'relative',
                                          marginRight:"5px",
                                      }}
                                      className={relevantSite !== site? styles.admin_top_bar_site_item : null}
                                      onClick={(e)=>{e.preventDefault();handleChangeSite(site)}}
                                    >
                                    <Typography   
                                      variant="h6"
                                      noWrap
                                      component="div"
                                      sx={{
                                        margin:' 0 10px',
                                        fontFamily: 'Roboto',
                                        fontWeight: 600,
                                        letterSpacing: '0',
                                        color: relevantSite !== site ? '#070A0E':'#070A0E',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        height:'53px',
                                        cursor: 'pointer',
                                        fontSize:'1rem',
                                        
                                      }} 
                                    >
                                    <div style={{display:'flex',alignItems:'center'}} >
                                      <div>
                                        <SiteIcon/> 
                                      </div>
                                        <div style={{margin:'0 8px'}}>{site}</div>
                                          <div  >
                                                <span className={styles.sites_events_number_circle}>
                                                {sitesEventObject && sitesEventObject[site] 
                                                  ? sitesEventObject[site].length :
                                                  0 }
                                                </span> 
                                          </div>
                                    </div>     
                                    </Typography>
                                    </div>
                                    {/* <div className={styles.horizontal_container}/> */}
                                  </React.Fragment>
                                  ))
                              )
                            : 
                              null
                          }
                      </div>
                  </div>
         
            :
              null
          }
       
           {/* PAGES LIST   */}
          {/*first - Site Manager pages list - top bar */}
          {/*second - Admin pages list - left side bar */}
          { 
              auth.userName && !currentPath.includes("/login") 
            ? 

                  !isAdmin
                ?
                  <Box    
                    sx={{ flexGrow: 1, display:'flex' }}
                  >
                    {SITE_MANAGER_PAGES.map((page) => (
                        ( page.allowed.includes(role) 
                          ? 
                            <div
                              
                              key={page.display}
                              style={{
                                position: 'relative',
                                left:'20px',
                                top:'6px'
                              }}
                            > 
                              <Button
                                key={page.display}
                                ref={focusPageRef}
                                onClick={()=>handleNavToPage(page.to)}
                                sx={{ my: 1,ml:0, 
                                  borderBottom: location.pathname === page.to?'4px solid #009DDC':'',
                                  fontFamily:'Roboto',
                                  fontWeight:500,
                                  fontSize:'16px',
                                  borderWidth:'80%',
                                  width:'160px',
                                  height:'47px',
                                  color:location.pathname  === page.to?'#070A0E' :'#6A88AC',
                                  textTransform:'none'
                                }}
                                startIcon={page.icon}
                              >

                                {page.display}
                              </Button> 
                            </div>
                          : 
                            null 
                        )
                      ))}
                  </Box> 
                : 

               
                  null
              : 
                null
            }
          


          {/* SETTINGS _ RIGHT SIDE (logout) */}
          {auth && auth.userName  && !currentPath.includes("/login")
              ?
          <Box 
            id="top-right-bar" 
            ref={refAdminRightSettingsBar} 
            className={styles.topBar_right_settings}
            style={{
              width:`${adminTopBarRighSettingsWidth}px`
            }}
          >

                  {isAdmin && !currentPath.includes("/login") &&
                    <Tooltip  title="Events" >
                    <Fab  
                      onClick={toggleEventsContainer} 
                      size='small' 
                      className={eventsContainerOpen ? styles.setting_icon_active :styles.setting_icon }
                      disableTouchRipple
                    >
                      <NotificationIcon />
                    </Fab>
                  </Tooltip> 
                  
                  
                  }
                <Tooltip  title="Profile" >
                  <Fab  
                    onClick={handleOpenUserMenu} 
                    size='small' 
                    className={styles.setting_icon}
                    disableTouchRipple
                  >
                    <LogoutUserIcon />
                  </Fab>
                </Tooltip> 
          
     
                <Menu
  sx={{
    mt: '39px',
    left: '8px',
    zIndex: 1000000000,
    minWidth: '150px', // Set a minimum width to prevent items from overlapping
  }}
  id="menu-appbar"
  anchorEl={anchorElUser}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  keepMounted
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  open={Boolean(anchorElUser)}
  onClose={handleCloseUserMenu}
>
  {auth.userName && !currentPath.includes("/login") ? 
    settings.map((setting) => (
      <MenuItem
        disableRipple
        sx={{
          zIndex: 999,
          borderRadius: '4px',
          minWidth: '150px'
        }}
        key={setting}
        onClick={setting === 'Logout' ? handleLogout : handleCloseNavMenu}
      >
        <Typography
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: '',
            fontSize: '0.9rem',
          }}
        >
          {setting === 'Logout' &&
            <Fab
              size='small'
              className={styles.setting_icon_no_hover}
              disableRipple
            >
              <LogoutIcon className={styles.logoutIcon} />
            </Fab>
          }
          {setting}
        </Typography>
      </MenuItem>
    )) 
    : 
    null
  }
</Menu>
        
         
          </Box> 
          :
          null
      } 
        </Toolbar>
    </AppBar>
      {/* PAGES LIST   */}
          {/*first - Site Manager pages list - top bar */}
          {/*second - Admin pages list - left side bar */}
          { 
              auth.userName && !currentPath.includes("/login")
            ? 

              role === ROLES.SITE_MANAGER 
                ?
                  null
                : 

               
                <div 
                    className={styles.side_bar}  
                    id="admin-left-side-container" 
                    ref={settingsContainerRef}
                    style={{width:sideBarIsopen ?'200px' :'60px',height:'100vh'}}

                  >
                    <div
                      className={styles.side_bar_top_container}
                    > 
                        <div
                          onClick={()=>setSideBarIsOpen(!sideBarIsopen)}
                          className={sideBarIsopen ? styles.side_bar_top_container_logo_no_hover:styles.side_bar_top_container_logo}
                        >

                        </div>
                        {
                            sideBarIsopen 
                          && 
                            <div className={styles.side_bar_top_logo_text} >
                              CARTEAV
                          </div>
                        }
                      {sideBarIsopen && <div 
                        className={styles.side_bar_close_button}
                        onClick={()=> setSideBarIsOpen(false)}  
                      >
                        <CloseSideBarIcon />
                      </div>
                      }
                    </div>
                    {ADMIN_PAGES.map((page) => (
                        ( page.allowed.includes(role) 
                          ? 
                            <div
                              style={{
                                width:sideBarIsopen ?'168px' :'44px',
                              }}
                              key={page.display}
                              className={ styles.side_bar_content}
                             
                            > 
                              <div
                                key={page.display}
                                ref={focusPageRef}
                                className={styles.side_bar_item}
                                onClick={()=>handleNavToPage(page.to)}
                                style={{ my: 1,ml:0, 
                                
                                  background:location.pathname  === page.to?'#353146':null,
                                  textTransform:'none'
                                }}
                               
                              >
                                <div style={{color:location.pathname  === page.to?'white':"#757284" ,height:'44px'}} >
                                  
                                  {page.icon}
                                </div>
                                {sideBarIsopen && <div style={{color:location.pathname  === page.to?'white' : "#757284"}}>
                                  {page.display}
                                </div>}
                              
                              </div> 
                            </div>
                          : 
                            null 
                        )
                      ))}
                      <div className={styles.side_bar_bottom_items}>
                          
                      {ADMIN_BOTTOM_SETTINGS.map((page) => (
                        ( page.allowed.includes(role) 
                          ? 
                            <div
                              
                              key={page.display}
                              className={ styles.side_bar_content}
                             
                            > 
                              <div
                                key={page.display}
                                ref={focusPageRef}
                                className={styles.side_bar_item}
                                onClick={()=>handleNavToPage(page.to)}
                                style={{ my: 1,ml:0, 
                                  width:sideBarIsopen ?'168px' :'44px',
                                  background:location.pathname  === page.to?'#353146':null,
                                  textTransform:'none'
                                }}
                               
                              >
                                <div style={{color:location.pathname  === page.to?'white':"#757284" ,height:'44px'}} >
                                  
                                  {page.icon}
                                </div>
                                <div style={{visibility:sideBarIsopen ? 'visible' :'hidden',color:location.pathname  === page.to?'white' : "#757284"}}>
                                  {page.display}
                                </div>
                              
                              </div> 
                            </div>
                          : 
                            null 
                        )
                      ))}
                        <div style={{color:'var(--white)',paddingLeft:'4px',fontSize:'0.8rem',paddingBottom:'4px'}}>v{version}</div>
                          
                      </div>


                  </div> 
              : 
                null
            }

           {
              auth.userName && !currentPath.includes("/login") && role && role !==ROLES.SITE_MANAGER 
            ? 
              <Event 
                eventsContainerOpen={eventsContainerOpen} 
                setEventsContainerOpen={setEventsContainerOpen} 
                eventContainerRef={eventContainerRef}  
                sitesEventObject={sitesEventObject}  
                setSitesEventObject={setSitesEventObject}
              />
            :
              null
          } 
    </>
  );
};
export default ResponsiveAppBar;
