
import React,{lazy ,Suspense} from "react";
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {ROLES_PAGES} from "../redux/context/allowedTypes";
import { AuthProvider } from "../redux/context/authProvider";
import ResponsiveAppBar from '../components/Dashboard/navBar/appBar';
import ResponsiveBodyContainer from '../components/Dashboard/responsiveBody/responsiveBodyContainer';
import './App.css';

const SiteManagement = lazy(() => import('../pages/siteManagement/siteManagement'));
const CartInfo = lazy(() => import('../pages/CartInfo/newCartInfo'));
const EventHistory = lazy(() => import('../pages/eventHistory/eventHistory'));
const CartManagement = lazy(() => import('../pages/cartManagement/cartManagement'));
const Login = lazy(() => import('../pages/login/login'));
const ProtectedRoutes = lazy(() => import('../hooks/Auth/RequiredAuth'));
const SiteOverView = lazy(() => import('../pages/siteManager/siteOverview'));
const Statistics = lazy(() => import('../pages/siteManager/statistics'));
const NotAllowed = lazy(() => import('../pages/InvalidUser/notAllowed'));
const Permissions = lazy(() => import('../pages/permissions/permissions'));
const FeedbackManagement = lazy(()=> import("../pages/feedbackManagement/feedbackManagement")) ;
const MissionsPage = lazy(()=> import("../pages/MissionsManagement/MissionsManagement")) ;


function App() {
  return (
  
    <Router>
     
       <AuthProvider>
        <Switch>
          <Provider store={store}>
          <ResponsiveAppBar  />
            <ResponsiveBodyContainer>
              <Suspense fallback={<div></div>}>
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                  <Route exact path="/" >
                    <Redirect to="/login" />
                  </Route>
                  {/* private Route*/}
            
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard} path="/dashboard_statistics">
                    <Statistics />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard} path="/dashboard_site">
                    <SiteOverView />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard_events} path="/dashboard_events">
                    <EventHistory />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.not_allowed} path="/not_allowed">
                    <NotAllowed />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.permissions} path="/permissions">
                    <Permissions /> 
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.cart_info} path="/cart/info/:cartId">
                      <CartInfo />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard_siteManagement} path="/dashboard_siteManagement">
                      <SiteManagement />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard_cartConfiguration} path="/dashboard_cartConfiguration">
                      <CartManagement />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard_cartConfiguration} path="/dashboard_feedbacks">
                      <FeedbackManagement />
                  </ProtectedRoutes>
                  <ProtectedRoutes allowed={ROLES_PAGES.dashboard_missions} path="/dashboard_missions">
                      <MissionsPage />
                  </ProtectedRoutes>
            </Suspense>
              </ResponsiveBodyContainer>
 
          </Provider>
        </Switch>
        </AuthProvider>
    </Router>
  );
}

export default App;
